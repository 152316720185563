<template>
  <div>
    <nav-bar>
      <div class="edition-center" v-loading="pageLoading">
        <div class="evaluation_points flex items-center justify-center">
          <img src="@/assets/point.png" alt="img" class="point_img" />
          <div class="text ml-5px">
            {{ settingValues.evaluateGetPoint }} puntos por evaluar una orden
          </div>
        </div>
        <div class="evaluate-container">
          <div class="title-bar">Evaluación</div>
          <div v-for="(item, index) in orderShopList" :key="index">
            <div class="evaluate-goods">
              <card-horizontal
                :moreVisible="true"
                :goodsInfo="item"
                :detailInfo="true"
              ></card-horizontal>
            </div>
            <div class="satisfaction_wrapper input-container">
              <div class="title">satisfacción：</div>
              <el-input
                v-model="customerSatisfaction"
                type="number"
                @blur="handleBlur"
                placeholder="Por favor ingrese un número del 1 al 10"
              ></el-input>
            </div>
            <div class="input-container">
              <div class="title">Por favor, escriba el comentario：</div>
              <el-input
                type="textarea"
                :rows="5"
                placeholder=""
                resize="none"
                v-model="item.commentDec"
              ></el-input>
              <div class="upload-box">
                <div class="add">
                  <upload-image
                    folder="head"
                    @listenPostImage="postImage($event, index, item)"
                    type="images"
                  />
                </div>
                <template v-if="uploadList">
                  <div
                    class="imgVisible col-delete"
                    v-for="(imgItem, imgIndex) in item.uploadList"
                    :key="imgIndex"
                  >
                    <img :src="imgItem | filterPicture" alt="" />
                    <i
                      @click="delImg(item.uploadList, imgIndex)"
                      class="el-icon-error"
                    />
                  </div>
                </template>
              </div>
            </div>
          </div>
          <div class="submit">
            <el-button
              type="success"
              round
              :loading="btnLoading"
              style="width: 400px"
              @click="handleSubmit"
            >
              Submission
            </el-button>
          </div>
        </div>
      </div>
    </nav-bar>
  </div>
</template>

<script>
// @ts-nocheck

import navBar from '@/components/navBar/index.vue'
import cardHorizontal from '@/components/product/card-horizontal.vue'
import UploadImage from '@/components/upload/image'
export default {
  components: {
    navBar,
    cardHorizontal,
    UploadImage
  },

  data() {
    return {
      orderShopList: [],
      uploadList: [],
      orderDetail: [],
      pageLoading: true,
      btnLoading: false,
      customerSatisfaction: '',
      settingValues: {}
    }
  },

  created() {
    this.getSetting()
    this.getOrderDetail()
  },

  methods: {
    async getSetting() {
      const {data: {result}} = await this.$axios.get('/setting/config/get')
      this.settingValues = result
      console.log("🚀 ~ getSetting ~ res:", result)
    },
    getOrderDetail() {
      this.$axios
        .post('childOrder/detail/' + this.$route.params.id)
        .then((res) => {
          this.orderDetail = res.data.result
          console.log('订单详情', this.orderDetail)

          // 获取未售后商品
          const unAfterSaleGoods = this.orderDetail.orderGoods.filter(
            (item) => !item.isAfterSale
          )
          if (unAfterSaleGoods) {
            if (this.$route.params.orderGoodsId) {
              this.orderShopList = unAfterSaleGoods.filter(
                (item) =>
                  !item.comment && item.id == this.$route.params.orderGoodsId
              )
            } else {
              // 获取可评价商品
              this.orderShopList = unAfterSaleGoods.filter(
                (item) => !item.comment
              )
            }
          }
          this.pageLoading = false
        })
        .catch(() => {
          this.pageLoading = false
        })
    },

    handleSubmit() {
      this.handleSatisfaction()
    },

    submitData() {
      let comments = []
      for (let i = 0; i < this.orderShopList.length; i++) {
        let item = {
          orderGoodsId: this.orderShopList[i].id,
          content: this.orderShopList[i].commentDec,
          imagesUrls: JSON.stringify(this.orderShopList[i].uploadList) || '[]'
        }
        if (!this.orderShopList[i].commentDec) {
          this.$message('El comentario no puede estar vacío')
          return
        }
        comments.push(item)
      }

      let form = {
        comments: comments,
        orderId: this.orderDetail.orderId,
        childOrderId: this.orderDetail.id
      }

      console.log(form)
      this.btnLoading = true
      this.$axios
        .post('/order/commentOrder', form)
        .then((res) => {
          if (res.data.code === 200) {
            this.$message.success('Comentario exitoso') //评价成功
            setTimeout(() => {
              this.$router.back(-1)
            }, 2000)
          } else {
            this.$message.success(res.data.message)
          }
        })
        .catch(() => {
          this.btnLoading = false
        })
    },

    postImage(url, index, item) {
      console.log(url, index)
      if (!item.uploadList) item.uploadList = []
      item.uploadList.push(url)
      this.$forceUpdate()
      console.log(item)
    },

    delImg(imgList, imgIndex) {
      imgList.splice(imgIndex, 1)
      this.$forceUpdate()
    },

    handleBlur() {
      if (+this.customerSatisfaction > 10 || +this.customerSatisfaction < 0) {
        this.customerSatisfaction = null
        this.$message.warning('Por favor ingrese un número del 1 al 10')
        return
      }
    },

    handleSatisfaction() {
      if (!this.customerSatisfaction) {
        this.$message.warning('Por favor complete la satisfacción')
      }
      let params = {
        customerSatisfaction: +this.customerSatisfaction,
        childOrderId: this.orderDetail.id
      }
      this.$axios.post('/childOrder/fillInSatisfaction', params).then((res) => {
        this.submitData()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.edition-center {
  width: 1200px;
  margin: 0 auto;
}

.evaluate-container {
  margin: 10px 0 40px 0;
  .title-bar {
    font-size: 20px;
    font-weight: bold;
  }
  .evaluate-goods {
    padding: 10px 20px;
    margin-top: 20px;
    background: #f9fbf9;
  }

  .input-container {
    margin: 40px 0;
    .title {
      margin-bottom: 10px;
    }
    .upload-box {
      display: flex;
      .add {
        width: 120px;
        margin: 20px 20px 0 0;
        img {
          width: 100%;
        }
      }

      .upload,
      .imgVisible {
        display: inline-block;
        width: 120px;
        margin: 10px 20px 0 0;
        img {
          display: block;
          width: 100%;
          height: 120px;

          object-fit: cover;
        }
      }

      // 删除图标
      .col-delete {
        position: relative;
        .el-icon-error {
          position: absolute;
          top: -7px;
          right: -7px;
          display: none;
          color: #999;
          font-size: 20px;
          cursor: pointer;
          &:hover {
            color: #333;
          }
        }
        &:hover {
          .el-icon-error {
            display: block;
          }
        }
      }
    }

    ::v-deep .el-textarea__inner {
      font-size: 16px;
    }
  }

  .submit {
    text-align: center;
  }
}
.evaluation_points {
  width: 1200px;
  height: 40px;
  background: linear-gradient(180deg, #d4ebc9 0%, #f3ffed 100%);
  .point_img {
    width: 14px;
    height: 14px;
    object-fit: cover;
  }
  .text {
    height: 36px;
    font-size: 18px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #3db823;
    line-height: 36px;
  }
}
</style>
<style lang="scss">
.satisfaction_wrapper {
  .el-input__inner {
    width: 460px;
    height: 56px;
    background: #f9fbf9;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    border: 1px solid #e7e7e7;
  }
}
</style>
